<template>
  <a-card :style="{ marginTop: '4px' }">
    <a-form :model="form" layout="inline">
      <a-form-item style="width: 13%">
        <a-input v-model:value="form.orderId" placeholder="合同号" />
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-select v-model:value="form.orderSettlement" style="width:100%" allowClear placeholder="结算方式">
          <a-select-option v-for="item in settlementModes" :key="item.value">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-input v-model:value="form.salesmanId" placeholder="业务员" />
      </a-form-item>
      <a-form-item style="width: 27%">
        <a-range-picker v-model:value="form.timeLimit" style="width:100%" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
          :showTime="false" placeholder="推送时间" />
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-input style="width: 100%" v-model:value="form.startAddress" placeholder="始发地">
          <template #prefix>始</template>
        </a-input>
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-input style="width: 100%" v-model:value="form.endAddress" placeholder="目的地">
          <template #prefix>终</template>
        </a-input>
      </a-form-item>
      <a-form-item style="width: 13%">
        <a-space>
          <a-button type="primary" @click="search">查询</a-button>
          <a-button @click="resetSearch">重置</a-button>
          <a-button type="dashed" @click="exportData" :disabled="exportDisabled">导出Excel</a-button>
          <!--            <a-button type="link" @click="open = !open">{{open ? '收起':'展开'}}-->
          <!--              <UpOutlined v-if="open" />-->
          <!--              <DownOutlined v-else />-->
          <!--            </a-button>-->
        </a-space>
      </a-form-item>
      <a-form-item label="办事处" style="width: 100%">
        <!-- <a-select v-model:value="form.officeOrgIds" mode="multiple">
                <a-select-option v-for="item in orgs" :key="item" :value="item.id">{{item.name}}</a-select-option>
              </a-select> -->
        <a-checkable-tag v-model:checked="orgCheckedAll" @change="orgChangeAll" class="checked-tag">全部</a-checkable-tag>
        <template v-for="item in orgs" :key="item.id">
          <a-checkable-tag v-model:checked="item.checked" @change="orgChange" class="checked-tag">{{ item.name
          }}</a-checkable-tag>
        </template>
      </a-form-item>
    </a-form>
    <a-table :dataSource="dataSource" :row-key="record => record.orderId" :loading="loading" :pagination="pagination"
      :scroll="{ x: 1500 }" :style="{ marginTop: '16px' }" bordered size="small"
      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <a-table-column :width="120" title="运输日期" data-index="pushTime" fixed="left" />
      <a-table-column :width="120" title="下单时间" data-index="orderTime" fixed="left" />
      <a-table-column :width="200" title="合同编号" data-index="orderId" />
      <a-table-column :width="100" title="订单状态" data-index="orderStatus.label" />
      <a-table-column :width="200" title="区域来源" data-index="officeOrgName" />
      <a-table-column :width="200" title="起始地" data-index="startAddress" />
      <a-table-column :width="200" title="目的地" data-index="endAddress" />
      <a-table-column :width="100" title="信息来源" data-index="salesman" />
      <a-table-column :width="100" title="部门来源" data-index="salesOrg" />
      <a-table-column :width="200" title="客户姓名" data-index="customerName" />
      <a-table-column :width="100" title="客户类型" data-index="customerType.label" />
      <a-table-column :width="100" title="保险成本" data-index="insuranceCost" />
      <a-table-column :width="100" title="累计发车价" data-index="transportCost" />
      <a-table-column :width="100" title="合同总金额" data-index="contractAmt" />
      <a-table-column :width="100" title="结算状态" data-index="settlementStatus.label" />
      <a-table-column :width="100" title="已结算金额" data-index="settledAmt" />
      <a-table-column :width="100" title="未结算金额" data-index="settledNotAmt" />
      <a-table-column :width="100" title="到付金额" data-index="paymentArrival" />
      <a-table-column :width="60" title="发票" data-index="isInvoice.label" />
      <a-table-column :width="100" title="结算方式" data-index="orderSettlement.label" />
      <a-table-column :width="200" title="记账对象" data-index="customerName" />
      <a-table-column :width="100" title="车到收" data-index="paidAmt" />
      <a-table-column :width="60" title="税率" data-index="invoiceRate" />
      <a-table-column :width="100" title="税费" data-index="invoiceCost" />
      <a-table-column :width="100" title="返款金额" data-index="refundFee" />
      <a-table-column :width="100" title="返款结算状态" data-index="refundSettlementStatus.label" />
      <a-table-column :width="100" title="返款已结算金额" data-index="refundSettledAmt" />
      <a-table-column :width="100" title="返款对象" data-index="refunder" />
      <a-table-column :width="100" title="其他费用" data-index="additionalFeeTotal" />
      <a-table-column :width="100" title="其他费用结算状态" data-index="additionalSettlementStatus.label" />
      <a-table-column :width="100" title="其他费用已结算金额" data-index="additionalSettledAmt" />
      <a-table-column :width="100" title="毛利润" data-index="grossProfit" />
      <a-table-column :width="300" title="备注" data-index="remark" />
    </a-table>
  </a-card>
</template>
<script>
// import { list as orgList } from '@/api/system/organzition'
import { useStore } from 'vuex'
import { orderReportPage, orderReportExport } from '@/api/trans/finance/bill'

import { reactive, ref, toRefs } from 'vue'
import { onMounted } from '@vue/runtime-core'

import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
export default {
  setup () {
    const store = useStore()
    const form = reactive({
      orderId: '',
      vinNo: '',
      orderSettlement: null,
      salesmanId: '',
      timeLimit: [],
      startAddress: '',
      endAddress: '',
      officeOrgIds: []
    })

    const state = reactive({
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
        onChange: (page, pageSize) => {
          state.pagination.current = page
          state.pagination.pageSize = pageSize
          loadData()
        }
      },
      exportDisabled: false,
      open: false,
      loading: false,
      orgs: [],
      dataSource: [],
      detailsLength: 0,
      orgCheckedAll: false,
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ]
    })

    const useForm = Form.useForm
    const { resetFields } = useForm(form, ref({}))

    const exportData = () => {
      state.exportDisabled = true
      orderReportExport({
        ...form,
        startTime: form.timeLimit[0],
        endTime: form.timeLimit[1]
      }, '订单报表-财务').then(res => {
        setTimeout(function () {
          state.exportDisabled = false
        }, 10000)
      })
    }

    const loadData = () => {
      state.loading = true
      orderReportPage({
        ...form,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        startTime: form.timeLimit[0],
        endTime: form.timeLimit[1]
      }).then(res => {
        if (res.code === 10000) {
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }

    onMounted(() => {
      state.orgs = store.state.app.orgTypeList.map(val => { return { ...val, checked: false } })
      // orgList({ orgType: 1 }).then(res => {
      //   if (res.code === 10000) {
      //     state.orgs = res.data.map(val => { return { ...val, checked: false } })
      //   }
      // })
      loadData()
    })

    const search = () => {
      state.pagination.current = 1
      loadData()
    }

    const resetSearch = () => {
      resetFields()
      search()
    }

    const orgChange = () => {
      form.officeOrgIds = state.orgs.filter(val => val.checked).map(val => val.id)
      state.orgCheckedAll = form.officeOrgIds.length === state.orgs.length
      search()
    }

    const orgChangeAll = checked => {
      state.orgs.forEach(val => { val.checked = checked })
      orgChange()
    }
    return {
      form,
      ...toRefs(state),
      search,
      resetSearch,
      orgChange,
      orgChangeAll,
      loadData,
      exportData
    }
  },
  components: {
    UpOutlined,
    DownOutlined
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
